import { Link, Links, LiveReload, Scripts } from "@remix-run/react";
import { Grid, Heading, Section, Text } from "sparta";
import Logo from "~/assets/logos/General-White.svg";
import WebBlock from "../layout/WebBlock";

interface IServerError {
  caught: any;
}
export default function ServerError({ caught }: IServerError) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Uh-oh!</title>
        <Links />
      </head>
      <body>
        <WebBlock color="var(--dark-blue)" id="homepage-error">
          <Section center>
            <div>
              <Section center height="150px">
                <img src={Logo} alt="G2X DoD" height={80} />
              </Section>
              <Heading as="h1" weight="bold" align="center">
                {caught.status !== 404
                  ? "We're experiencing technical difficulties :/"
                  : "Uh-oh! Something went wrong"}
              </Heading>
              <Section center>
                <Grid
                  columns="auto auto"
                  gap={caught.status === 404 ? undefined : "30px"}
                  className="link-buttons"
                >
                  <a href="/">Go Home</a>
                  {caught.status !== 404 ? (
                    <Link to="/logout">Log out</Link>
                  ) : (
                    <div />
                  )}
                </Grid>
              </Section>
              <Section center column height="150px">
                <Heading as="h3">
                  {caught.status === 404
                    ? "Sorry, this page could not be found"
                    : caught?.statusText}
                </Heading>
                <pre>{caught?.data}</pre>
              </Section>
            </div>
          </Section>
        </WebBlock>
        <Scripts />
      </body>
    </html>
  );
}
