import { Theme, ThemePanel } from "@radix-ui/themes";
import { ThemeOwnProps } from "@radix-ui/themes/dist/cjs/props";
import "@radix-ui/themes/styles.css";
import { ThemeProvider } from "next-themes";
import * as React from "react";
import "./SpartaProvider.css";

interface SpartaProviderProps extends ThemeOwnProps {
  children: React.ReactNode;
  withPanel?: boolean;
}

export function SpartaProvider({ children, withPanel, ...props }: SpartaProviderProps) {
  return (
    <ThemeProvider attribute="class">
      <Theme accentColor="green" style={{ width: "100%" }} {...props}>
        {children}
        {withPanel && <ThemePanel />}
      </Theme>
    </ThemeProvider>
  );
}
