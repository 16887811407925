import { ScrollArea, Toaster, useAppearance } from "sparta";
import { SideNavContextProvider } from "~/context/SideNavContext";
import { SideNav } from "./SideNav/SideNav";
import { TopNav } from "./TopNav/TopNav";

interface AppLayoutProps {
  children: React.ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
  const { theme } = useAppearance();

  return (
    <SideNavContextProvider>
      <Toaster richColors position="bottom-left" theme={theme as "dark" | "light"} />
      <SideNav>
        <TopNav>
          <ScrollArea
            id="main-scroll"
            type="auto"
            size="2"
            style={{ maxHeight: "calc(100vh - 40px)", height: "calc(100vh - 40px)" }}
          >
            {children}
          </ScrollArea>
        </TopNav>
      </SideNav>
    </SideNavContextProvider>
  );
}
