import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { Box, Flex } from "sparta";
import { useSideNav } from "~/context/SideNavContext";

export function ResponsiveSideNavContainer({ children }: PropsWithChildren) {
  const { open, setOpen } = useSideNav();

  return (
    <>
      <Box display={{ initial: "block", md: "none" }}>
        <AnimatePresence>
          {open ? (
            <>
              <motion.div
                initial={{ left: "-100%" }}
                animate={{ left: 0 }}
                exit={{ left: "-100%" }}
                style={{ position: "fixed", zIndex: 5, backgroundColor: "var(--color-background)", height: "100vh" }}
              >
                {children}
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.3 }}
                exit={{ opacity: 0 }}
                style={{
                  position: "fixed",
                  zIndex: 4,
                  backgroundColor: "var(--color-background)",
                  backdropFilter: "blur(10px)",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: "100%",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </>
          ) : null}
        </AnimatePresence>
      </Box>
      <Flex display={{ initial: "none", md: "flex" }}>{children}</Flex>
    </>
  );
}
