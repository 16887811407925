interface IntercomSettings {
  api_base: string;
  app_id: string;
  name: string;
  user_id: string | number;
  email: string;
  created_at: number | string;
}

declare global {
  interface Window {
    Intercom?: (command: string, ...args: any[]) => void;
    intercomSettings?: IntercomSettings;
  }

  interface IntercomFunction extends Function {
    q?: any[];
    c?: (args: any) => void;
  }
}

// Modifying this script to be a function that accepts user data
export const setupIntercom = (user) => {
  // Ensures the script runs only in a browser environment
  if (typeof window === "undefined" || typeof document === "undefined") {
    return;
  }

  // Defining base Intercom settings as a Partial type
  let intercomSettings: Partial<IntercomSettings> = {
    api_base: "https://api-iam.intercom.io",
    app_id: "si177418",
    user_id: user.id,
  };

  // If user data is available, we extend the base settings
  if (user) {
    intercomSettings = {
      ...intercomSettings,
      name: user.name,
      email: user.email,
      created_at: user.createdAt,
    };
  }

  // Updating TypeScript casting here to satisfy the partial assignment earlier
  window.intercomSettings = intercomSettings as IntercomSettings;

  // Intercom script setup updated with declared interface
  (() => {
    const w = window as any; // Using 'any' to bypass strict type checking
    const ic = w.Intercom as IntercomFunction | undefined;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      const i: IntercomFunction = (...args) => {
        i.c?.(args);
      };
      i.q = [];
      i.c = (args) => {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = () => {
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${intercomSettings.app_id}`;
        const x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
};
