import { Link } from "@remix-run/react";
import { Flex, IconButton } from "sparta";

interface ActionIconProps {
  children: React.ReactNode;
  to?: string;
  onClick?: () => void;
}

export function ActionIcon({ children, to, onClick }: ActionIconProps) {
  return (
    <Flex
      align="center"
      style={{
        borderLeft: "1px solid var(--gray-a6)",
      }}
      overflow="hidden"
      width="40px"
      height="40px"
    >
      <IconButton variant="ghost" style={{ height: "100%", width: "100%" }} asChild={!!to} onClick={onClick}>
        {to ? <Link to={to}>{children}</Link> : children}
      </IconButton>
    </Flex>
  );
}
