import { Link } from "@remix-run/react";
import { PropsWithChildren, forwardRef } from "react";
import { Flex, Text } from "sparta";

type AccountActionProps = {
  href: string;
  title?: string;
  icon: React.ReactNode;
};

export const AccountAction = forwardRef<HTMLAnchorElement, PropsWithChildren<AccountActionProps>>(
  ({ href, title, icon, ...props }, forwardedRef) => {
    return (
      <Link to={href} {...props} ref={forwardedRef}>
        <Flex align="center">
          {icon}
          <Text>{title}</Text>
        </Flex>
      </Link>
    );
  },
);
