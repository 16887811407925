import { useLocation } from "@remix-run/react";
import { createContext, useContext, useEffect, useState } from "react";

interface SideNavContextProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SideNavContext = createContext<SideNavContextProps>({
  open: false,
  setOpen: () => {},
});

export function SideNavContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <SideNavContext.Provider
      value={{
        open,
        setOpen,
      }}
    >
      {children}
    </SideNavContext.Provider>
  );
}

export function useSideNav() {
  const context = useContext(SideNavContext);
  if (!context) {
    throw new Error("useSideNav must be used within a SideNavProvider");
  }
  return context;
}
