import { ApolloClient, InMemoryCache } from "@apollo/client/index.js";
import { createUploadLink } from "apollo-upload-client";

let backendURI = "";

if (typeof window !== "undefined" && window.ENV) {
  backendURI = window.ENV.BACKEND_URI;
} else if (typeof ENV !== "undefined") {
  backendURI = ENV.BACKEND_URI;
}

const uri = `${backendURI}/api/graphql`;

export const apolloClient = new ApolloClient({
  link: createUploadLink({ uri }),
  cache: new InMemoryCache(),
});
